@tailwind base;
@tailwind components;
@tailwind utilities;

.light > *::-webkit-scrollbar-track {
  background: #fff;
}

.dark {
  color: #dfdfdf;
}

.dark > *::-webkit-scrollbar-track {
  background: #1a1b25;
}

.light *::-webkit-scrollbar-thumb {
  background-color: rgba(148, 196, 242, 0.1);
  border-radius: 10px;
  border: 1px solid #b2b6ce;
}

.dark * {
  scrollbar-width: 10px;
  scrollbar-color: #1a1b25 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}
.dark *::-webkit-scrollbar-track {
  background: #1a1b25;
  border-color: #9aa2ac;
}

.dark *::-webkit-scrollbar-thumb {
  background-color: #1a1b25;
  border-radius: 10px;
  border: 1px solid #9aa2ac;
}

.dark.bg-main-bg:after {
  content: ' ';
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background-color: #1a1b25;
  z-index: -1;
}

* {
  font-family: 'Lato', sans-serif;
}
.bearish {
  background-color: rgb(185, 28, 28);
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
}
.bullish {
  background-color: rgb(21, 128, 61);
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
}

.blue-flame {
  color: rgb(56, 189, 248);
}

.MuiTableRow-root {
  background-color: #fff;
}

/*.highlightChange {
  background-color: #f8ffb4;
  transition: all 0.5s ease-in-out;
}

.highlightChangeOut {
  background-color: #fff !important;
  transition: all 0.5s ease-in-out;
}
.onlyHighlightOnce {
  background-color: #fff !important;
  transition: all 0.5s ease-in-out;
}*/

/* .filter-row {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
} */

/* .filter-btns {
  width: 90%;
  margin: 10px auto;
} */

.filter-btns .bearish:hover,
.filter-btns .bearish.active {
  background-color: rgb(248, 113, 113);
}

.filter-btns .bullish:hover,
.filter-btns .bullish.active {
  background-color: rgb(74, 222, 128);
}

.MuiSwitch-switchBase input,
.MuiSwitch-thumb {
  background-color: rgb(56 189 248) !important;
}
.MuiTouchRipple-root,
.MuiSwitch-thumb {
  color: rgb(56 189 248) !important;
}

.MuiTableCell-body {
  padding: 16px 10px !important;
  font-size: 14px !important;
}

/* .MuiTableCell-head span {
  white-space: nowrap;
} */

/* .MuiTablePagination-selectLabel,
.MuiTablePagination-select,
.MuiTablePagination-displayedRows,
.MuiTablePagination-menuItem,
.filter-label,
.checkbox-label {
  font-size: 14px !important;
} */

.MuiTablePagination-select {
  display: flex !important;
  align-items: center !important;
}

.MuiTablePagination-actions svg {
  height: 24px !important;
  width: 24px !important;
}

.dark .MuiTablePagination-selectLabel,
.dark .MuiTablePagination-select,
.dark .MuiTablePagination-displayedRows,
.dark .MuiTablePagination-menuItem,
.dark .MuiTablePagination-actions svg,
.dark .MuiTablePagination-selectIcon {
  color: #b2b6ce !important;
  fill: #b2b6ce !important;
}

.range-label,
.bearish span,
.bullish span,
input::placeholder {
  font-size: 13px !important;
}
.tag-name {
  font-size: 11px !important;
  color: #70769b;
}
.dark .tag-name.no-style {
  color: #b2b6ce !important;
  border-color: #b2b6ce !important;
}
.dark .no-style-wrap:hover .no-style {
  color: #1a1b25 !important;
}

/* .scanner {
  max-height: 100vh;
  height: 100vh;
} */

.Pane {
  flex-basis: 1 !important;
}

.multi-screen-scanner .group-title {
  max-width: 100% !important;
  width: 100% !important;
}
.multi-screen-scanner .group-header,
.multi-screen-scanner .delay-info {
  max-width: 95% !important;
  width: 95% !important;
}
.after-market-hours {
  font-weight: 600;
  font-size: 19px;
  color: #70769b;
  font-style: italic;
}
.dark .after-market-hours {
  color: #70769b;
}

.Pane.horizontal,
.SplitPane .Pane.vertical:first-of-type {
  overflow-y: scroll;
}

#scan_scanner {
  min-height: 100vh;
  position: relative;
}

.react-datepicker-wrapper {
  display: flex;
  align-items: center;
}
.react-datepicker__input-container {
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
  padding-top: 0;
  padding-bottom: 0;
  border: 0;
  --tw-shadow: 0;
  --tw-shadow-colored: 0;
  box-shadow: 0;
  display: flex;
  align-items: center;
}
.react-datepicker__input-container input {
  --tw-border-opacity: 1;
  padding: 0;
  border: 0;
  margin-top: 0 !important;
  --tw-text-opacity: 1;
  width: 100%;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #70769b;
  width: 90px;
}

.react-datepicker__input-container input:focus {
  outline: transparent;
  --tw-ring-color: transparent;
}

.multi-screen-scanner .not-active svg {
  display: none;
  height: 0;
  width: 0;
}

.multi-screen-scanner .MuiTableCell-head,
.multi-screen-scanner .MuiTableRow-root .MuiTableCell-body {
  font-size: 12px !important;
  padding: 8px 10px !important;
}

.multi-screen-scanner .range-label-multi {
  font-size: 11px !important;
}

.multi-screen-scanner .MuiTableContainer-root,
.multi-screen-scanner .MuiTable-root {
  transform: rotateX(180deg);
  -moz-transform: rotateX(180deg); /* Mozilla */
  -webkit-transform: rotateX(180deg); /* Safari and Chrome */
  -ms-transform: rotateX(180deg); /* IE 9+ */
  -o-transform: rotateX(180deg); /* Opera */
}

.Pane.vertical {
  overflow-y: auto;
}

.scanner .MuiPaper-elevation {
  box-shadow: none !important;
}

.dark .scanner .MuiPaper-elevation,
.dark .MuiTableRow-head {
  background: #1a1b25;
}

.MuiTableRow-head .MuiTableCell-head {
  padding: 18px 10px !important;
}

.MuiTableRow-head {
  border-top: 0.5px solid #b2b6ce !important;
  border-bottom: 0.5px solid #b2b6ce !important;
}
.dark .MuiTableRow-head {
  border-top: 0.5px solid #b2b6ce !important;
  border-bottom: 0.5px solid #b2b6ce !important;
}

.MuiTableCell-head {
  border-top: 0.5px solid #b2b6ce !important;
  border-bottom: 0.5px solid #b2b6ce !important;
}

.symbol-item {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
  color: #3b7e2e;
}

.dark .symbol-item {
  color: #56ccf2;
}

.scanner-item span:not(.color-item, .symbol-item) {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #70769b;
}

.dark .scanner-item span:not(.color-item, .symbol-item) {
  color: #b2b6ce;
}

.color-item {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.dark .color-item {
  color: #fff;
}
.dark .no-color-item {
  color: #70769b;
}

.MuiTableRow-head .MuiTableCell-head,
.table-header-light {
  font-family: 'Lato', sans-serif !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  color: #70769b !important;
}

.table-header-light svg {
  color: #70769b !important;
}

.MuiTableBody-root .MuiTableRow-root:nth-child(even) {
  background: rgba(0, 116, 223, 0.02) !important;
}
.MuiTableBody-root .MuiTableRow-root:nth-child(even):hover,
.pagination-button:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.dark .MuiTableBody-root .MuiTableRow-root:nth-child(odd) {
  background: #1a1b25 !important;
}
.dark .MuiTableBody-root .MuiTableRow-root:nth-child(even) {
  background: rgba(112, 118, 155, 0.15) !important;
}

/* .MuiTableBody-root .MuiTableRow-root {
  border: none !important;
  border-top: 7.5px solid transparent !important;
  border-bottom: 7.5px solid transparent !important;
} */

.MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
  border: none !important;
}

.MuiTableBody-root .MuiTableRow-root .MuiTableCell-root .MuiTableCell-body {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.MuiTable-root {
  border-collapse: collapse !important;
  border-spacing: 0 15px !important;
}

.main-container {
  overflow-y: scroll;
  max-height: 100vh;
}
.sidebar {
  height: 100vh;
}
.sidebar a.active {
  color: white;
  background: #3b7e2e;
}
.sidebar a.active path,
.sidebar a.active circle {
  fill: white;
}
.MuiTablePagination-actions {
  display: none;
}

/* User Loading  */
#loading-user {
  top: 0;
  left: 0;
  color: #464b45;
}
#loading-user img {
  width: 65px;
}

.dark #loading-user {
  background: #252634;
  color: #dfdfdf;
}

/* Pages  */
#page {
  padding: 0 1rem;
  width: 90%;
  max-width: 1200px;
  margin: auto;
}
#page h1 {
  font-size: 2.5rem;
  padding-top: 25px;
  color: #464b45;
}
#page a {
  color: #3b7e2e;
  text-decoration: underline;
}

/** Fixed Chart */
.chart-container {
  position: sticky;
  background: #fff;
  z-index: 9999;
  width: 100%;
  margin-top: -2.25rem;
  padding-top: 20px;
  padding-bottom: 20px;
  top: -2.25rem;
}

.chart-container .chart {
  width: 100%;
  position: relative;
}

.chart-visible {
  /* margin-top: 350px; */
}
